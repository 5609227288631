<template>
  <div>
    <div class="px-8 py-4 flex flex-shrink-0 border-b">
      <transition name="slide-fade" mode="out-in">
        <div class="flex-shrink-0 hidden lg:block" :key="profile.id">
          <div class="overflow-hidden rounded-full  inline-block">
            <img :src="profile.photo ? profile.photo.medium : '/assets/static/profile.png'" :alt="profile.fullName" width="90" height="90">
          </div>
        </div>
      </transition>

      <transition name="slide-fade" mode="out-in">
        <div class="flex flex-col justify-center lg:ml-6" :key="profile.id">
          <transition name="slide-fade" mode="out-in">
            <h5 class="uppercase mb-2 hidden md:block" v-if="activeScreen === 'messaging'" :key="activeScreen">Messaging</h5>
            <h5 class="uppercase mb-2 hidden md:block" v-if="activeScreen === 'notes'" :key="activeScreen">Logging notes for</h5>
          </transition>
            <h2 class="mb-0 md:mb-2 font-bold lg:font-medium text-lg lg:text-4xl"><span class="text-xs uppercase mr-2 align-middle md:hidden">Logging notes for </span>{{ profile.fullName }}</h2>
          <h5 class="mb-0 hidden md:block" v-if="userType === 'mentor'">Age: ??</h5>
        </div>
      </transition>

      <div class="flex-shrink-0 flex items-center ml-auto" v-if="activeScreen === 'messaging'">
        <a v-if="profile.enableVideoAudioChat" href="#" @click.prevent="toggleAudio" class="w-8 lg:w-12 h-8 lg:h-12 rounded-full bg-gray-400 hover:bg-blue-500 transition-all duration-300 inline-flex items-center justify-center ml-4 relative">
          <svg class="w-5 lg:w-8 h-5 lg:h-8" fill="none" height="24" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg>
          <div
              class="w-4 h-4 border-red-700 border-4 bg-white absolute rounded-full top-0 right-0"
              v-if="(comm.audioActive[profile.id] === true)"
          ></div>
        </a>
        <a v-if="profile.enableVideoAudioChat" href="#" @click.prevent="toggleVideo" class="w-8 lg:w-12 h-8 lg:h-12 rounded-full bg-gray-400 hover:bg-blue-500 transition-all duration-300 inline-flex items-center justify-center ml-4 relative">
          <svg class="w-5 lg:w-8 h-5 lg:h-8" fill="none" height="24" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polygon points="23 7 16 12 23 17 23 7"/><rect height="14" rx="2" ry="2" width="15" x="1" y="5"/></svg>
          <div
              class="w-4 h-4 border-red-700 border-4 bg-white absolute rounded-full top-0 right-0"
              v-if="(comm.videoActive[profile.id] === true)"
          ></div>
        </a>
      </div>
    </div>
    <div class=" bg-red-700 text-white text-center container mx-auto px-6 py-2" v-if="(comm.audioActive[profile.id] === true)">Currently waiting in audio call</div>
    <div class=" bg-red-700 text-white text-center container mx-auto px-6 py-2" v-if="(comm.videoActive[profile.id] === true)">Currently waiting in video chat</div>
  </div>
</template>

<script>
import EventBus from '../../../EventBus';

export default {
  name: "MentoringChatHeader",
  props: ['profile', 'activeScreen', 'userType', 'comm'],
  methods: {
    toggleVideo() {
      EventBus.$emit('MentoringToggleVideo');
    },
    toggleAudio() {
      EventBus.$emit('MentoringToggleAudio');
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
