<template>
<div class="h-full flex">
  <vue-draggable-resizable :drag-handle="'.drag-handle'" :parent="true" class="fixed z-50 bg-white draggable-video-window border-none rounded" class-name-dragging="dragging" class-name-handle="handles" :min-width="300" :min-height="280" :w="dragWidth"
    :h="dragHeight" :x="dragX" :y="dragY" v-if="videoOpen">
    <div class="flex flex-col h-full drag-handle cursor-move relative">
      <a href="#" @click.prevent="videoOpen = !videoOpen" class="absolute right-0 top-0 mt-2 mr-2 bg-white w-12 h-12 rounded-full flex items-center justify-center" style="z-index:99999">
        <svg height="2em" style="fill: currentColor" viewBox="0 0 512 512" width="2em">
          <path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>
      </a>
      <!-- <div class="p-4 bg-gray-400 drag-handle cursor-move flex-grow-0 flex-shrink-0">
          <h4 class="mb-0">Video Chat</h4>

        </div> -->
      <mentoring-video-chat class="flex-1 min-h-0" :mentor="profile.type === 'mentor' ? profile.id : activeMentee" :mentee="profile.type === 'mentor' ? activeMentee : profile.id" :user-id="profile.id" :comm="comm" />
    </div>
  </vue-draggable-resizable>
  <vue-draggable-resizable :drag-handle="'.drag-handle'" :parent="true" class="fixed z-50 bg-white draggable-video-window border border-gray-700" class-name-dragging="dragging" class-name-handle="handles" :min-width="300" :min-height="280" :w="dragWidth"
    :h="dragHeight" :x="dragX" :y="dragY" v-if="audioOpen">
    <div class="flex flex-col h-full">
      <div class="p-4 bg-gray-300 drag-handle cursor-move flex-grow-0 flex-shrink-0">
        <h4 class="mb-0">Audio Call</h4>
        <a href="#" @click.prevent="audioOpen = !audioOpen" class="absolute right-0 top-0 mt-2 mr-2">
          <svg height="2em" style="fill: currentColor" viewBox="0 0 512 512" width="2em">
            <path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>
        </a>
      </div>
      <mentoring-audio-chat class="flex-1 min-h-0" :mentor="profile.type === 'mentor' ? profile.id : activeMentee" :mentee="profile.type === 'mentor' ? activeMentee : profile.id" :user-id="profile.id" :comm="comm" />
    </div>
  </vue-draggable-resizable>
  <div class="absolute top-0 inset-x-0 flex justify-center z-40">
    <div class="bg-gray-700 text-white px-8 py-4 rounded-bl-lg rounded-br-lg" v-if="offline">Offline...</div>
  </div>
  <div class="w-full md:w-1/4 overflow-auto h-full absolute md:static top-0 md:top-auto left-0 md:left-auto bottom-0 md:bottom-auto right-0 md:right-auto bg-white z-10">
    <div class="border-b py-4 h-full">
      <div class="px-4">
        <h2 class="text-2xl lg:text-4xl font-bold py-3 pb-1 lg:pb-4" v-if="userType === 'mentor'">Mentees</h2>
        <h2 class="text-2xl lg:text-4xl font-bold py-3 pb-1 lg:pb-4" v-else>Mentors</h2>
      </div>

      <div class="flex flex-col justify-between" style="height:calc(100% - 80px)">

        <div class="flex flex-col px-4">
          <div v-for="person in mentees" class="hover:border-gray-300 rounded-lg transition-all duration-300 border border-gray-200 mb-2 flex flex-wrap" :class="{'bg-gray-200': person.id === activeMentee}">

            <div class="pl-4 py-4 flex-shrink-0 relative w-4/12 lg:w-2/12 relative">
              <div class="relative">
                <img :src="person.photo ? person.photo.thumb : '/assets/static/profile.png'" :alt="person.fullName" width="50" height="50" class="overflow-hidden rounded-full">
                <div
                    class="w-4 h-4 border-red-700 border-4 bg-white absolute rounded-full top-0 right-0"
                    v-if="(comm.audioActive[person.id] === true) || (comm.videoActive[person.id] === true)"
                ></div>
              </div>
              <a href="#" @click="activeMentee = person.id; activeScreen = 'messaging';" class="absolute inset-0"><span class="hidden">Open Messenger</span></a>
            </div>

            <div class="pl-4 py-4 flex flex-col justify-center min-w-0 flex-grow relative w-3/5 w-6/12">
              <div>{{ person.fullName }}</div>
              <div class="text-gray-500 text-sm pt-1 truncate" v-if="person.lastMessage">
                {{ person.id == profile.id ? person.firstName + ': ' : 'Me: ' }}
                {{ person.lastMessage.message }}
              </div>
              <a href="#" @click="activeMentee = person.id; activeScreen = 'messaging';" class="absolute inset-0"><span class="hidden">Open Messenger</span></a>
            </div>

            <div class="flex lg:flex-col relative w-full lg:pl-4 lg:w-4/12" :class="{hidden: activeMentee !== person.id, 'lg:visible': activeMentee !== person.id}">
              <a href="#" @click.prevent="activeMentee = person.id; activeScreen = 'messaging'; mobileTab = 'messaging'" :class="{ 'opacity-0 invisible': activeMentee !== person.id, 'bg-gray-100 text-gray-500 rounded-tr-lg ': activeScreen !== 'messaging' }"
                class="flex flex-auto items-center px-1 py-4 lg:py-2 text-xs  rounded-tr-lg truncate hover:bg-gray-300 transition-all duration-300 justify-center">Message</a>
              <a href="#" @click.prevent="activeMentee = person.id; activeScreen = 'notes'; mobileTab = 'messaging'" :class="{ 'opacity-0 invisible': activeMentee !== person.id, 'bg-gray-100 text-gray-500 rounded-br-lg': activeScreen !== 'notes' }"
                class="flex flex-auto items-center px-1 py-4 lg:py-2 text-xs  truncate rounded-br-lg hover:bg-gray-300 transition-all duration-300 justify-center">Log Notes</a>
              <a href="#" v-if="activeMentee !== person.id" @click="activeMentee = person.id; activeScreen = 'messaging'" class="absolute inset-0"><span class="hidden">Open Messenger</span></a>
            </div>

          </div>
        </div>

        <div class="flex flex-col border-t py-4">
          <div class="px-4">

             <!-- <div v-if="userType === 'mentor'"  class="hover:bg-blue-200 rounded-lg transition-all duration-300 border border-blue-400 mb-2 flex bg-blue-300">

               <div class="pl-4 py-8 flex-shrink-0 relative">
                 <div class="overflow-hidden rounded-full h-12 w-12 bg-blue-400 flex items-center justify-center">
                   <img src="/assets/static/public-forum.svg" width="30" height="30">
                 </div>
                 <a href="#" class="absolute inset-0"></a>
               </div>

               <div class="pl-4 py-8 flex flex-col justify-center min-w-0 flex-grow relative">
                 <div class="text-white">Mentor Forum/Notices</div>
                 <div class="text-blue-500 text-sm pt-1">
                   Checkout any mentoring updates and information.
                 </div>
                 <a href="/forum/mentor-forum" class="absolute inset-0"></a>
               </div>
             </div> -->

            <div class="hover:bg-yellow-400 rounded-lg transition-all duration-300 border border-yellow-600 mb-2 flex bg-yellow-500">

              <div class="pl-4 py-8 flex-shrink-0 relative">
                <div class="overflow-hidden rounded-full h-12 w-12 bg-yellow-600 flex items-center justify-center">
                  <img src="/assets/static/public-forum.svg" width="30" height="30">
                </div>
                <a href="#" class="absolute inset-0"></a>
              </div>

              <div class="pl-4 py-8 flex flex-col justify-center min-w-0 flex-grow relative">
                <div class="text-white">Public Forum</div>
                <div class="text-yellow-700 text-sm pt-1">
                  Post or comment of what others are saying.
                </div>
                <a href="/forum/public-forum" class="absolute inset-0"></a>
              </div>
            </div>
            <div class="hover:bg-red-400 rounded-lg transition-all duration-300 border border-red-600 mb-2 flex bg-red-500">

              <div class="pl-4 py-8 flex-shrink-0 relative">
                <div class="overflow-hidden rounded-full h-12 w-12 bg-red-600 flex items-center justify-center">
                  <img src="/assets/static/resources.svg" width="30" height="30">
                </div>
                <a href="#" class="absolute inset-0"></a>
              </div>

              <div class="pl-4 py-8 flex flex-col justify-center min-w-0 flex-grow relative">
                <div class="text-white"><span v-if="userType === 'mentor'" >Mentor</span><span v-else>Mentee</span> Resources</div>
                <div class="text-red-800 text-sm pt-1 truncate">
                  Browse our collection of <span v-if="userType === 'mentor'" >mentor</span><span v-else>mentee</span> resources
                </div>
                <a href="/mentor-resources" v-if="userType === 'mentor'" class="absolute inset-0"></a>
                <a v-else href="/resources" class="absolute inset-0"></a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div :class="{'translate-x-full': mobileTab !== 'messaging'}" v-if="menteeInfo" :key="menteeInfo ? menteeInfo.id : null" class="w-full md:w-2/4 border-l border-r overflow-auto flex flex-col absolute md:static top-0 md:top-auto left-0 md:left-auto bottom-0 md:bottom-auto right-0 md:right-auto bg-white z-20 transition-all duration-200 transform md:translate-x-0">
    <mentoring-chat-header :profile="menteeInfo" :active-screen="activeScreen" :user-type="userType" :comm="comm" />
    <a @click.prevent="mobileTab = null" href="#" class="md:hidden bg-gray-700 text-white text-xs flex items-center justify-center py-3">
      <svg viewBox="0 0 32 32" width="20" height="20" xmlns="http://www.w3.org/2000/svg" class="fill-current transform rotate-180 inline-block mr-2">
        <g>
          <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z"></path>
        </g>
      </svg>
      Back to Dashboard
    </a>
    <mentoring-chat-box v-if="activeScreen === 'messaging'" :messages="messages" :recipient="activeMentee" :user-id="profile.id" :loading-messages="loadingMessages" :reached-first-message="reachedFirstMessage" />
    <mentoring-chat-notes v-if="activeScreen === 'notes'" :recipient="activeMentee" :user-id="profile.id" :user-type="userType" />
  </div>
  <div v-else class="w-full md:w-2/4 md:border-l md:border-r overflow-auto p-8 flex items-center justify-center absolute md:static top-0 md:top-auto left-0 md:left-auto bottom-0 md:bottom-auto right-0 md:right-auto bg-white">
    <div v-if="profile" class="border px-6 py-10 rounded-lg bg-brand-500 text-white">
      <h3 class="text-center mb-0">Hello {{ profile.firstName }}, welcome to your dashboard</h3>
    </div>
    <div v-else>
      <div class="loading"></div>
    </div>
  </div>

  <div :class="{'translate-x-full': mobileTab !== 'profile'}" class="w-full md:w-1/4 overflow-auto absolute md:static top-0 md:top-auto left-0 md:left-auto bottom-0 md:bottom-auto right-0 md:right-auto bg-white z-30 transition-all duration-200 transform md:translate-x-0">
    <mentoring-profile :profile="profile" v-if="profile" />
  </div>
</div>
</template>

<script>
import EventBus from '../../EventBus';
import axios from 'axios';
import MentoringProfile from "@/components/mentoring/components/MentoringProfile";
import MentoringChatHeader from "@/components/mentoring/components/MentoringChatHeader";
import MentoringChatBox from "@/components/mentoring/components/MentoringChatBox";
import VueDraggableResizable from 'vue-draggable-resizable';
import Pusher from 'pusher-js';

export default {
  name: 'MentorDashboard',
  components: {
    MentoringChatBox,
    MentoringChatHeader,
    MentoringProfile,
    VueDraggableResizable
  },
  props: ['userType'],
  data() {
    return {
      mentees: [],
      profile: {},
      activeMentee: null,
      activeScreen: null,
      mobileTab: null,
      messages: [],
      loadingMessages: false,
      timerLoadingMessages: null, // save message fetching setInterval because we need to destroy it when component gets destroyed
      offline: false, // show a message when we are offline
      forcingMessageLoading: false, // prevent flashing of messages from previous conversation because of web request lag
      reachedFirstMessage: false, // did we get very first message from the server?
      messageBoxScrolledToTop: false,
      videoOpen: false,
      audioOpen: false,
      dragWidth: 400, // video popup centering
      dragHeight: 350, // video popup centering
      dragX: 523, // video popup centering
      dragY: 120, // video popup centering

      comm: {
        videoActive: [],
        audioActive: []
      }
    }
  },
  beforeCreate() {
    // Center video screen
    let browserWindowWidth = window.innerWidth
    let browserWindowHeight = window.innerHeight
    let browserHorizonHalf = browserWindowWidth / 2
    let browserVerticalHalf = browserWindowHeight / 2
    let dragHalfWidth = this.dragWidth / 2
    let dragHalfHeight = this.dragHalf / 2
    this.dragX = browserHorizonHalf - dragHalfWidth
    this.dragY = browserVerticalHalf - dragHalfHeight
    console.log(this.dragX);
  },
  mounted() {
    var _this = this;
    this.fetchFreshData();

    this.timerLoadingMessages = setInterval(function() {
      _this.fetchChatMessages();
    }, 1000);
    this.fetchChatMessages();

    EventBus.$on('MentoringTempChatMessageAdded', function(data) {
      _this.forcingMessageLoading = true;
    })
    EventBus.$on('MentoringScrolledToTop', function(data) {
      _this.messageBoxScrolledToTop = data.is;
    })
    EventBus.$on('MentoringToggleVideo', function(data) {
      _this.videoOpen = !_this.audioOpen ? !_this.videoOpen : _this.videoOpen;
    })
    EventBus.$on('MentoringToggleAudio', function(data) {
      _this.audioOpen = !_this.videoOpen ? !_this.audioOpen : _this.audioOpen;
    })
    EventBus.$on('OpenProfileMobileTab', function(data) {
      _this.mobileTab = _this.mobileTab === 'profile' ? null : 'profile';
    })


    //////////////////////////////// debug
    // document.getElementsByTagName("body")[0].onkeypress=function(e){
    //   var s = String.fromCharCode(e.which | e.witch );
    //   switch(s){
    //     case "r":
    //       _this.fetchFreshData();
    //       _this.fetchChatMessages();
    //   }
    // };
    //////////////////////////////// end debug
  },
  beforeDestroy() {
    clearInterval(this.timerLoadingMessages);
  },
  methods: {
    fetchFreshData() {
      var _this = this;
      let url = '';

      if (this.userType === 'mentor') {
        url = '/actions/mentoring-module/mentoring/get-mentor-dashboard-data';
      } else {
        url = '/actions/mentoring-module/mentoring/get-mentee-dashboard-data';
      }

      axios.post(url, {
          [this.$root.csrfName]: this.$root.csrfToken
        })
        .then(function(response) {
          _this.mentees = response.data.mentees;
          _this.profile = response.data.profile;
          _this.offline = false;

          _this.setupConnection();
        })
        .catch(function(error) {
          console.log(error);
          if (error === 'Error: Request failed with status code 400') {
            _this.offline = true;
          }
        })
        .then(function() {});
    },
    fetchChatMessages(force) {
      if (this.activeScreen !== 'messaging') {
        return false;
      }

      var _this = this;
      var firstTimestamp = this.messages.length === 0 ? null : this.messages[this.messages.length - 1].time;

      if (_this.profile === null || _this.activeMentee === null) {
        _this.messages = [];
        return 0;
      }

      if (force === true) {
        _this.loadingMessages = true;
        _this.forcingMessageLoading = true;
      }

      if (!this.loadingMessages || force === true) { // prevent multiple requests
        _this.loadingMessages = true;
        var mentorId = _this.userType === 'mentor' ? (_this.profile ? _this.profile.id : null) : (_this.activeMentee !== null ? _this.activeMentee : null);
        var menteeId = _this.userType === 'mentee' ? (_this.profile ? _this.profile.id : null) : (_this.activeMentee !== null ? _this.activeMentee : null);

        axios.post('/actions/mentoring-module/mentoring/get-chat-messages', {
            [this.$root.csrfName]: this.$root.csrfToken,
            mentorId: mentorId,
            menteeId: menteeId,
            firstTimestamp: firstTimestamp,
            currentNumberOfMessages: _this.messages.length,
            getExtra: !_this.reachedFirstMessage && _this.messageBoxScrolledToTop
          })
          .then(function(response) {
            if (!_this.forcingMessageLoading) {
              _this.messages = response.data.messages;
              _this.reachedFirstMessage = response.data.reachedTop;
              _this.offline = false;
            }
          })
          .catch(function(error) {
            console.log(error);
            if (error === 'Error: Request failed with status code 400') {
              _this.offline = true;
            }
          })
          .then(function() {
            _this.loadingMessages = false;
            _this.forcingMessageLoading = false;
          });
      }
    },
    async setupConnection() {
      const pusher = await this.getPusherInstance();
      this.channel = pusher.subscribe('presence-mentoring-platform');

      this.mentees.forEach(mentee => {
        let mentorId = this.profile.type === 'mentor' ? this.profile.id : mentee.id;
        let menteeId = this.profile.type === 'mentee' ? this.profile.id : mentee.id;
        let myId = this.profile.id;
        let oppositeId = mentee.id;
        this.channel.bind(`client-signal-${mentorId}-${menteeId}-${oppositeId}-video`, (signal) =>
        {
          console.log('video signal received');
          this.$set(this.comm.videoActive, oppositeId, signal.enabled);
          // this.comm.videoActive[oppositeId] = signal.enabled;
        });
        this.channel.bind(`client-signal-${mentorId}-${menteeId}-${oppositeId}-audio`, (signal) =>
        {
          console.log('audio signal received');
          this.$set(this.comm.audioActive, oppositeId, signal.enabled);
          // this.comm.audioActive[oppositeId] = signal.enabled;
        });
      });
    },
    getPusherInstance() {
      return new Pusher(this.$root.pusherKey, {
        authEndpoint: '/actions/mentoring-module/mentoring/auth-pusher',
        cluster: this.$root.pusherCluster,
        auth: {
          params: {
            [this.$root.csrfName]: this.$root.csrfToken
          }
        }
      });
    }
  },
  computed: {
    menteeInfo() {
      var _this = this;

      if (_this.activeMentee === null || _this.mentees.length === 0) {
        return null;
      }

      for (let i = 0; i < _this.mentees.length; i++) {
        if (_this.mentees[i].id === _this.activeMentee) {
          return _this.mentees[i];
        }
      }

      return null;
    }
  },
  watch: {
    activeMentee() {
      this.$nextTick(() => {
        this.messages = [];
        this.fetchChatMessages(true);
        this.reachedFirstMessage = false;
      });
    }
  }
}
</script>

<style lang="scss">
.draggable-video-window {
    z-index: 100 !important;

    @media all and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
    }
}
.handles {
    position: absolute;
}
.handles-tl {
    top: -10px;
    left: -10px;
    width: 10px;
    height: 10px;
    cursor: nw-resize;
}

.handles-tm {
    top: -10px;
    left: 0;
    height: 10px;
    right: 0;
    cursor: n-resize;
}

.handles-tr {
    top: -10px;
    right: -10px;
    width: 10px;
    height: 10px;
    cursor: ne-resize;
}

.handles-ml {
    top: 0;
    bottom: 0;
    left: -10px;
    width: 10px;
    cursor: w-resize;
}

.handles-mr {
    top: 0;
    bottom: 0;
    width: 10px;
    right: -10px;
    cursor: e-resize;
}

.handles-bl {
    bottom: -10px;
    left: -10px;
    width: 10px;
    height: 10px;
    cursor: sw-resize;
}

.handles-bm {
    bottom: -10px;
    left: 0;
    right: 0;
    height: 10px;
    cursor: s-resize;
}

.handles-br {
    bottom: -10px;
    right: -10px;
    width: 10px;
    height: 10px;
    cursor: se-resize;
}
</style>
