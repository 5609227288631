<template>
  <div class="relative">
    <audio class="w-full h-full h-max-h-full audio-there z-10" ref="audio-there" autoplay></audio>
    <div class="z-30 absolute top-0 right-0 bottom-0 left-0 items-center justify-center flex" v-if="connected === false">
      <div class="text-center bg-blue-100 rounded-lg px-4 py-2 text-black opacity-50">Waiting for the<br>other side</div>
    </div>
    <div class="z-30 absolute top-0 right-0 bottom-0 left-0 items-center justify-center flex" v-if="connected === 'connected'">
      <div class="text-center bg-blue-100 rounded-lg px-4 py-2 text-black opacity-50">Connected</div>
    </div>
    <div class="z-30 absolute top-0 right-0 bottom-0 left-0 items-center justify-center flex" v-if="connected === 'disconnected'">
      <div class="text-center bg-blue-100 rounded-lg px-4 py-2 text-black opacity-50"><span v-if="userId === mentee">Mentor left</span><span v-else>Mentee left</span></div>
    </div>
  </div>
</template>

<script>
import Pusher from 'pusher-js';
import Peer from 'simple-peer';

export default {
  name: "AudioChat",
  props: ['mentor', 'mentee', 'userId'],
  data() {
    return {
      channel: null,
      stream: null,
      peers: {},
      connected: false,
      signaling: null
    }
  },
  async mounted() {
    await this.setupAudioChat();
  },
  methods: {
    startAudioChat() {
      var _this = this;
      this.getPeer(this.userId, true);
      this.signaling = setInterval(() => {
        this.channel.trigger(`client-signal-${_this.mentor}-${_this.mentee}-${_this.userId === _this.mentor ? _this.mentor : _this.mentee}-audio`, { enabled: true });
      }, 4000);
    },
    getPeer(userId, initiator) {
      var _this = this;
      if(this.peers[userId] === undefined) {
        let peer = new Peer({
          initiator,
          stream: this.stream,
          trickle: false
        });
        peer.on('signal', (data) => {
          _this.channel.trigger(`client-signal-audio-${_this.mentor}-${_this.mentee}-${_this.userId === _this.mentor ? _this.mentor : _this.mentee}`, {
            userId: userId,
            data: data
          });
        })
        .on('stream', (stream) => {
          const audioThere = _this.$refs['audio-there'];
          audioThere.srcObject = stream;
        })
        .on('close', () => {
          clearInterval(_this.signaling);
          _this.channel.trigger(`client-signal-${_this.mentor}-${_this.mentee}-${_this.userId === _this.mentor ? _this.mentor : _this.mentee}-audio`, { enabled: false });

          console.log(3, _this.peers);
          const peer = _this.peers[userId];
          if(peer !== undefined) {
            peer.destroy();
          }
          delete _this.peers[userId];
        });
        _this.peers[userId] = peer;
      }
      return this.peers[userId];
    },
    async setupAudioChat(userId) {
      var _this = this;
      // To show pusher errors
      Pusher.logToConsole = true;
      const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      // const audioHere = this.$refs['audio-here'];
      // audioHere.srcObject = stream;
      this.stream = stream;
      const pusher = await this.getPusherInstance();
      this.channel = pusher.subscribe('presence-mentoring-platform');
      this.channel.bind(`client-signal-audio-${_this.mentor}-${_this.mentee}-${_this.userId === _this.mentor ? _this.mentee : _this.mentor}`, (signal) =>
      {
        const peer = this.getPeer(signal.userId, false);
        peer.signal(signal.data);
        _this.connected = 'connected';
      });

      // When user gets authenticatet correctly
      this.channel.bind('pusher:subscription_succeeded', (signal) => {
        console.log('Connection established');
        _this.startAudioChat();
      })

      // When other side connects to chat
      this.channel.bind('pusher:member_added', (signal) => {
        _this.connected = 'connected';
      });

      // When other side leaves
      this.channel.bind('pusher:member_removed', (signal) => {
        _this.connected = 'disconnected';
      });

      console.log(pusher.allChannels());
    },
    getPusherInstance() {
      return new Pusher(this.$root.pusherKey, {
        authEndpoint: '/actions/mentoring-module/mentoring/auth-pusher',
        cluster: this.$root.pusherCluster,
        auth: {
          params: {
            [this.$root.csrfName]: this.$root.csrfToken
          }
        }
      });
    }
  },
  beforeDestroy() {
    var _this = this;
    this.stream.getTracks().forEach(stream => { stream.stop(); });
    // this.peers.forEach(peer => peer.close());
    Object.keys(this.peers).forEach(function(key) {
      _this.peers[key].destroy();
      delete _this.peers[key];
    });
    this.channel.pusher.unsubscribe('presence-mentoring-platform');
  }
}
</script>

<style scoped>
  .video-here {
    width: 300px;
    max-width: 30%;
  }
</style>