import axios from "axios";
import EventBus from '../../EventBus'

EventBus.$on('MentoringSendMessage', function (data) {
    axios.post('/actions/mentoring-module/mentoring/add-chat-message', data)
        .then(function (response) {
            // handle success
            // console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
});

EventBus.$on('MentoringSendNote', function (data) {
    axios.post('/actions/mentoring-module/mentoring/add-chat-note', data)
        .then(function (response) {
            // handle success
            // console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
});